(function() {
	'use strict';
	try {
		if (document.querySelector('.cmp-countdown')) {
			var
				counter = document.querySelector('.cmp-countdown'),
				expiryTime = counter.dataset.expiration,
				countDownDate = new Date(expiryTime).getTime(),
				elDays = counter.querySelector('.days .numbers'),
				elHrs = counter.querySelector('.hrs .numbers'),
				elMins = counter.querySelector('.mins .numbers'),
				elSec = counter.querySelector('.sec .numbers'),
				timestamp = '';

			const urlParams = new URLSearchParams(window.location.search);
			const todayParam = urlParams.get('today');

			if (todayParam) {
				// Split the parameter into date and time parts
				const dateAndTimeParts = todayParam.split('--');

				// Check if both date and time parts are present
				if (dateAndTimeParts.length === 2) {
					const datePart = dateAndTimeParts[0];
					const timePart = dateAndTimeParts[1];

					// Construct the date string
					const dateString =
						datePart.replace(/-/g, ' ') +
						' ' +
						timePart.replace(/-/g, ':') +
						' GMT+0000';

					// Create a Date object
					const dateObject = new Date(dateString);

					// Get the timestamp
					timestamp = dateObject.getTime();
				}
			}

			// fallback to not show NaN
			if (isNaN(countDownDate)) {
				countDownDate = new Date().getTime();
			}

			// Update the count down every 1 second
			var x = setInterval(function() {
				var
					now = timestamp ? (timestamp = timestamp + 1000) : new Date().getTime(), // Get today's date and time
					distance = countDownDate - now, // Find the distance between now and the count down date
					// Time calculations for days, hours, minutes and seconds
					days = Math.floor(distance / (1000 * 60 * 60 * 24)),
					hrs = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
					mins = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
					sec = Math.floor((distance % (1000 * 60)) / 1000);

				// Display the result in the counter element
				elDays.innerHTML = days < 10 ? ('0'+days) : days;
				elHrs.innerHTML = hrs < 10 ? ('0'+hrs) : hrs;
				elMins.innerHTML = mins < 10 ? ('0'+mins) : mins;
				elSec.innerHTML = sec < 10 ? ('0'+sec) : sec;

				// If the count down is finished, remove counter
				if (distance < 0) {
					clearInterval(x);
					counter.remove();
				}
			}, 1000);
		}

	} catch (error) {
		// singe data layer error tracking
		if (typeof sdl !== 'undefined') {
			console.error(error);
			sdl.push({
				system: {
					error: {
						type: error.name,
						message: error.message,
					}
				},
				event: 'system.error'
			});
		}
	}
})();
